<template>
  <div class="dashboard">
    <ParticleNetwork />
    <div class="welcome-screen">
      <h1>Welcome to My CV</h1>
      <p style="padding-bottom: 25px">Navigate through the dashboard to explore different sections of my CV.</p>
      <div class="control-panels">
        <button class="panel" @click="openPopup('About')">
          <i class="fas fa-user"></i>
          <span>About Me</span>
        </button>
        <button class="panel" @click="openPopup('Skills')">
          <i class="fas fa-cogs"></i>
          <span>Skills</span>
        </button>
        <button class="panel" @click="openPopup('Experience')">
          <i class="fas fa-briefcase"></i>
          <span>Experience</span>
        </button>
        <button class="panel" @click="openPopup('Documents')">
          <i class="fas fa-file-archive"></i>
          <span>Documents</span>
        </button>
        <button class="panel" @click="openPopup('Contact')">
          <i class="fas fa-envelope"></i>
          <span>Contact</span>
        </button>
      </div>
    </div>
    <Popup :visible="popupVisible" @close="closePopup">
      <component :is="currentComponent" />
    </Popup>
  </div>
</template>

<script>
import ParticleNetwork from './ParticleNetwork.vue';
import Popup from './Popup.vue';
import About from './AboutView.vue';
import Skills from './SkillsView.vue'
import Experience from "@/components/ExperienceView.vue";
import Documents from "@/components/DocumentsView.vue";
import Contact from "@/components/ContactView.vue";

export default {
  name: 'HomeView',
  components: {
    ParticleNetwork,
    Popup,
    About,
    Skills,
    Experience,
    Documents,
    Contact
  },
  data() {
    return {
      popupVisible: false,
      currentComponent: null,
    };
  },
  methods: {
    openPopup(component) {
      this.currentComponent = component;
      this.popupVisible = true;
    },
    closePopup() {
      this.popupVisible = false;
      this.currentComponent = null;
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import url('@fortawesome/fontawesome-free/css/all.css');

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#app {
  height: 100%;
}

.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  color: #fff;
  text-align: center;
}

.welcome-screen {
  margin-bottom: 25px;
  z-index: 1;
}

.control-panels {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 1;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid #fff;
  border-radius: 10px;
  color: #fff;
  font-size: 1.2em;
  transition: transform 0.3s, background 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 10px #7f00ff, 0 0 20px #7f00ff, 0 0 30px #7f00ff;
  cursor: pointer;
}

.panel i {
  font-size: 2em;
  margin-bottom: 10px;
}

.panel:hover {
  transform: scale(1.1);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 20px #7f00ff, 0 0 30px #7f00ff, 0 0 40px #7f00ff;
}

.panel span {
  margin-top: 5px;
}
</style>
