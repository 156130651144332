<template>
  <div class="stars">
    <div v-for="star in 5" :key="star" class="star">
      <i :class="getStarClass(star)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      filledStars: 0
    };
  },
  mounted() {
    this.fillStars();
  },
  methods: {
    fillStars() {
      for (let i = 1; i <= this.rating; i++) {
        setTimeout(() => {
          this.filledStars = i;
        }, i * 500); // Adjust the timing as needed
      }
    },
    getStarClass(star) {
      if (star <= this.filledStars) {
        return 'fas fa-star';
      } else {
        return 'far fa-star';
      }
    }
  }
};
</script>

<style scoped>
.stars {
  display: flex;
}

.star i {
  color: #ffd700;
  margin-left: 5px;
  transition: transform 0.3s ease-in-out;
}

.star i.fas {
  animation: pop 0.5s ease-in-out;
}

@keyframes pop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
