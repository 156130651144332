<template>
  <span>{{ displayedText }}</span>
</template>

<script>
export default {
  props: ['text'],
  data() {
    return {
      displayedText: '',
      intervalId: null,
      characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    };
  },
  mounted() {
    this.initDecryption();
  },
  methods: {
    initDecryption() {
      this.displayedText = this.generateRandomText(this.text.length);
      setTimeout(this.decryptText, 1000); // Start decryption after a delay
    },
    generateRandomText(length) {
      let randomText = '';
      for (let i = 0; i < length; i++) {
        randomText += this.characters.charAt(Math.floor(Math.random() * this.characters.length));
      }
      return randomText;
    },
    decryptText() {
      let i = 0;
      this.intervalId = setInterval(() => {
        if (i < this.text.length) {
          this.displayedText =
              this.text.slice(0, i + 1) +
              this.generateRandomText(this.text.length - i - 1);
          i++;
        } else {
          clearInterval(this.intervalId);
          this.displayedText = this.text;
        }
      }, 100); // Speed of decryption
    },
  },
};
</script>

<style scoped>
span {
  color: #7f00ff;
  font-weight: bold;
}
</style>
