<template>
  <div class="timeline-popup">
    <h1>Experience & Education</h1>
    <div class="timeline">
      <div class="timeline-item" v-for="(item, index) in timelineItems" :key="index">
        <div :class="['timeline-dot', item.color]"></div>
        <div :class="['timeline-content', item.color]">
          <h2><DecryptingText :text="item.title" /></h2>
          <p><DecryptingText :text="item.date" /></p>
          <p><DecryptingText :text="item.location" /></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DecryptingText from './DecryptingText.vue';

export default {
  name: 'TimelinePopup',
  components: {
    DecryptingText
  },
  data() {
    return {
      timelineItems: [
        { title: 'Primary School', date: '2009 - 2014', location: 'Macedonia', color: 'purple' },
        { title: 'Secondary School', date: '2014 - 2017', location: 'Macedonia', color: 'yellow' },
        { title: 'Immigration in Switzerland', date: 'July 2017', location: '', color: 'cyan' },
        { title: 'German Course', date: 'August - December 2017', location: '', color: 'green' },
        { title: 'Sek E', date: '2018 - 2020', location: '8 and 9 grade', color: 'blue' },
        { title: 'Apprenticeship at Fluance AG', date: '2020 - 2022', location: '', color: 'orange' },
        { title: 'Apprenticeship change', date: '2022', location: 'Fluance AG was sold to Kheops AG', color: 'red' },
        { title: 'Apprenticeship finished', date: '2024', location: '', color: 'yellow' },
      ],
    };
  },
};
</script>

<style scoped>
.timeline-popup {
  padding: 20px;
  background: #1e1e2f;
  color: #eaeaea;
  border-radius: 20px;
  width: 750px;
  max-width: 800px;
  margin: auto;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  animation: fadeIn 1s ease-in-out;
}

h1 {
  text-align: center;
  animation: fadeInDown 1s ease-in-out;
}

.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;
}

.timeline-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  animation: fadeInUp 1s ease-in-out;
}

.timeline-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  animation: bounce 2s infinite;
}

.timeline-content {
  background: #2c2c3e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  width: calc(100% - 60px);
}

.timeline-content h2 {
  margin: 0;
  font-size: 1.2em;
  animation: fadeInLeft 1s ease-in-out;
}

.timeline-content p {
  margin: 5px 0 0;
  animation: fadeInRight 1s ease-in-out;
}

.timeline-dot.purple {
  background: #9b59b6;
}

.timeline-dot.yellow {
  background: #f1c40f;
}

.timeline-dot.cyan {
  background: #1abc9c;
}

.timeline-dot.green {
  background: #2ecc71;
}

.timeline-dot.blue {
  background: #3498db;
}

.timeline-dot.orange {
  background: #e67e22;
}

.timeline-dot.red {
  background: #e74c3c;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}
</style>
