<template>
  <div class="about">
    <div class="content">
      <div class="profile-pic" @mouseover="showTooltip" @mouseleave="hideTooltip" @click="openPicture">
        <div class="flip-card">
          <div class="flip-card-inner" :class="{ flipped: tooltipVisible }">
            <div class="flip-card-front">
              <img src="../assets/portrait1.jpg" alt="Profile Picture">
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>First Name:</label>
          <DecryptingText text="Metodij" />
        </div>
        <div class="form-group col-md-6">
          <label>Last Name:</label>
          <DecryptingText text="Krshkov" />
        </div>
      </div>
      <div class="form-group">
        <label>Email:</label>
        <DecryptingText text="metodijkrshkov01001101@gmail.com" />
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label>Date of Birth:</label>
          <DecryptingText text="13 January 2004" />
        </div>
        <div class="form-group col-md-6">
          <label>Nationality:</label>
          <DecryptingText text="Macedonian" />
        </div>
      </div>
      <div class="form-group">
        <label>Phone Number:</label>
        <DecryptingText text="0782209271" />
      </div>
      <h2>Languages</h2>
      <div class="form-group">
        <label>Macedonian:</label>
        <AnimatedBar :progress="100" />
      </div>
      <div class="form-group">
        <label>German:</label>
        <AnimatedBar :progress="90" />
      </div>
      <div class="form-group">
        <label>English:</label>
        <AnimatedBar :progress="90" />
      </div>
      <div class="form-group">
        <label>Serbian/Croatian:</label>
        <AnimatedBar :progress="60" />
      </div>
      <h2>Interests</h2>
      <div class="form-group interests">
        <div class="interest">
          <i class="fas fa-futbol"></i>
          <DecryptingText text="Soccer" />
        </div>
        <div class="interest">
          <i class="fas fa-laptop-code"></i>
          <DecryptingText text="Programming" />
        </div>
        <div class="interest">
          <i class="fas fa-dumbbell"></i>
          <DecryptingText text="Fitness" />
        </div>
        <div class="interest">
          <i class="fas fa-gamepad"></i>
          <DecryptingText text="Gaming" />
        </div>
        <div class="interest">
          <i class="fas fa-music"></i>
          <DecryptingText text="Techno" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DecryptingText from './DecryptingText.vue';
import AnimatedBar from './AnimatedBar.vue';

export default {
  name: 'AboutView',
  components: {
    DecryptingText,
    AnimatedBar,
  },
  data() {
    return {
      step: 0,
      tooltipVisible: false
    };
  },
  mounted() {
    this.showContent();
  },
  methods: {
    showContent() {
      for (let i = 1; i <= 10; i++) {
        setTimeout(() => {
          this.step = i;
        }, i * 2000); // Adjust timing for each step
      }
    },
    showTooltip() {
      this.tooltipVisible = true;
    },
    hideTooltip() {
      this.tooltipVisible = false;
    },
    openPicture() {
      window.open(require('../assets/portrait1.jpg'), "_blank");
    }
  }
};
</script>

<style scoped>
.about {
  padding: 30px;
  background: #1e1e2f;
  color: #eaeaea;
  border-radius: 20px;
  width: 700px;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.profile-pic {
  display: flex;
  justify-content: center;
  margin-bottom: 50px; /* Added more space below the picture */
  position: relative;
  cursor: pointer;
}

.flip-card {
  background-color: transparent;
  width: 150px;
  height: 150px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-inner.flipped {
  transform: rotateY(0deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 50%;
}



.profile-pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  border: 2px solid #7f00ff;
  box-shadow: 0 0 10px rgba(127, 0, 255, 0.5);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  background: #2c2c3e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-group.col-md-6 {
  flex: 1;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

span {
  margin-top: 5px;
}

.interests {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.interest {
  display: flex;
  align-items: center;
  gap: 10px;
}

.interest i {
  font-size: 1.5em;
  color: #7f00ff;
}
</style>
