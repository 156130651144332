<template>
  <div class="contact-popup">
    <h1>Contact Me</h1>
    <div class="contact-content">
      <div class="form-group">
        <DecryptingText text="Your Name" />
        <input v-model="name" type="text" required class="form-control" />
      </div>
      <div class="form-group">
        <DecryptingText text="your-email@example.com" />
        <input v-model="email" type="email" required class="form-control" />
      </div>
      <div class="form-group">
        <DecryptingText text="Your Message" />
        <textarea v-model="message" required class="form-control"></textarea>
      </div>
      <button @click="sendMessage" class="btn">Send Message</button>
    </div>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import DecryptingText from './DecryptingText.vue';

export default {
  name: 'ContactView',
  components: {
    DecryptingText
  },
  data() {
    return {
      name: '',
      email: '',
      message: ''
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return re.test(String(email).toLowerCase());
    },
    sendMessage() {
      if (!this.name) {
        alert('Please enter your name.');
        return;
      }

      if (!this.email) {
        alert('Please enter your email.');
        return;
      }

      if (!this.validateEmail(this.email)) {
        alert('Please use a valid email address.');
        return;
      }

      if (!this.message) {
        alert('Please enter your message.');
        return;
      }

      const serviceID = 'service_2feijro';
      const templateID = "template_5vqpoer";
      const userID = "ZBDS8Yn5c9xi4Dr3r";

      const templateParams = {
        from_name: this.name,
        from_email: this.email,
        message: this.message,
      };

      emailjs.send(serviceID, templateID, templateParams, userID)
          .then(() => {
            alert('Message sent successfully!');
            this.name = '';
            this.email = '';
            this.message = '';
          })
          .catch((error) => {
            console.error('There was an error sending the message:', error);
            alert('Failed to send message. Please try again later.');
          });
    }
  }
};
</script>

<style scoped>
.contact-popup {
  padding: 30px;
  background: #1e1e2f;
  color: #eaeaea;
  border-radius: 20px;
  width: 700px;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

h1 {
  text-align: center;
}

.contact-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  background: #2c2c3e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

label {
  font-weight: bold;
  margin-bottom: 5px;
}

input,
textarea {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #3c3c4e;
  color: #eaeaea;
}

textarea {
  resize: none;
  height: 100px;
}

.btn {
  padding: 10px;
  background: #7f00ff;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;
}

.btn:hover {
  background: #9b2fff;
}
</style>
