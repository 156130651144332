<template>
  <div class="documents-popup">
    <h1>Documents</h1>
    <div class="documents-content">
      <div class="document" @mouseover="hoverDocument('certificate1')" @mouseleave="leaveDocument('certificate1')" @click="openDocument(certificate1)">
        <div class="document-border" :class="{ flipped: isFlipped.certificate1 }">
          <div class="document-front">
            <img src="../assets/certificate2_page-0001.jpg" alt="Certificate 1" />
          </div>
          <div class="document-back">
            <span>Open Document</span>
          </div>
        </div>
        <DecryptingText text="Apprenticeship certificate" />
      </div>
      <div class="document" @mouseover="hoverDocument('certificate2')" @mouseleave="leaveDocument('certificate2')" @click="openDocument(certificate2)">
        <div class="document-border" :class="{ flipped: isFlipped.certificate2 }">
          <div class="document-front">
            <img src="../assets/certificate1_page-0001.jpg" alt="Certificate 2" />
          </div>
          <div class="document-back">
            <span>Open Document</span>
          </div>
        </div>
        <DecryptingText text="C1 English certificate" />
      </div>
      <div class="document">
        <div class="document-border coming-soon">
          <span>Coming Soon</span>
        </div>
        <DecryptingText text="Letter of recommendation" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import DecryptingText from './DecryptingText.vue';

export default {
  name: 'DocumentsView',
  components: {
    DecryptingText
  },
  setup() {
    const isFlipped = reactive({
      certificate1: false,
      certificate2: false
    });

    const hoverDocument = (certificate) => {
      isFlipped[certificate] = true;
    };

    const leaveDocument = (certificate) => {
      isFlipped[certificate] = false;
    };

    const openDocument = (fileName) => {
      window.open(fileName, "_blank");
    };

    return {
      isFlipped,
      hoverDocument,
      leaveDocument,
      openDocument,
      certificate1: require('../assets/certificate2_page-0001.jpg'),
      certificate2: require('../assets/certificate1_page-0001.jpg')
    };
  }
};
</script>

<style scoped>
.documents-popup {
  padding: 30px;
  background: #1e1e2f;
  color: #eaeaea;
  border-radius: 20px;
  width: 1000px;
  height: 500px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}

h1 {
  text-align: center;
}

.documents-content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.document {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.document-border {
  width: 280px;
  height: 350px;
  border: 10px solid #7f00ff;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px rgba(127, 0, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.document-border.flipped {
  transform: rotateY(180deg);
}

.document-front, .document-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.document-front {
  display: flex;
  justify-content: center;
  align-items: center;
}

.document-back {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  background: #2c2c3e;
  color: #7f00ff;
  font-weight: bold;
  font-size: 1.2em;
}

.document-border img {
  width: 100%;
  height: 100%;
}

.document-border.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2c2c3e;
  color: #7f00ff;
  font-weight: bold;
  font-size: 1.2em;
}
</style>
