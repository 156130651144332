<template>
  <div class="skills-popup">
    <h1>Skills</h1>
    <div class="content">
      <div class="final-skills">
        <div class="skill">
          <span>Frontend</span>
          <div class="progress">
            <AnimatedBar :progress="85" />
          </div>
        </div>
        <div class="skill">
          <span>Backend</span>
          <div class="progress">
            <AnimatedBar :progress="55" />
          </div>
        </div>
      </div>
      <div class="languages-section">
        <h2>Languages</h2>
        <div class="languages">
          <div class="language">
            <DecryptingText text="Vue.js" />
            <StarRating :rating="4" />
          </div>
          <div class="language">
            <DecryptingText text="React" />
            <StarRating :rating="4" />
          </div>
          <div class="language">
            <DecryptingText text="PHP" />
            <StarRating :rating="4" />
          </div>
          <div class="language">
            <DecryptingText text="Java" />
            <StarRating :rating="3" />
          </div>
          <div class="language">
            <DecryptingText text="C#" />
            <StarRating :rating="3" />
          </div>
          <div class="language">
            <DecryptingText text="SQL" />
            <StarRating :rating="4" />
          </div>
          <div class="language">
            <DecryptingText text="HL7 Streams" />
            <StarRating :rating="2" />
          </div>
          <div class="language">
            <DecryptingText text="Python" />
            <StarRating :rating="2" />
          </div>
        </div>
      </div>
      <div class="code-management-section">
        <h2>Code Control Management</h2>
        <div class="skill">
          <DecryptingText text="Git:" />
          <div class="progress">
            <AnimatedBar :progress="90" />
          </div>
        </div>
        <div class="skill">
          <DecryptingText text="Netlify:" />
          <div class="progress">
            <AnimatedBar :progress="70" />
          </div>
        </div>
        <div class="skill">
          <DecryptingText text="Jira:" />
          <div class="progress">
            <AnimatedBar :progress="75" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DecryptingText from './DecryptingText.vue';
import AnimatedBar from './AnimatedBar.vue';
import StarRating from './StarRating.vue'; // Assuming you have a StarRating.vue component

export default {
  name: 'SkillsView',
  components: {
    DecryptingText,
    AnimatedBar,
    StarRating,
  },
};
</script>

<style scoped>
.skills-popup {
  padding: 30px;
  background: #1e1e2f;
  color: #eaeaea;
  border-radius: 20px;
  width: 700px;
  height: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

h1, h2 {
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.final-skills {
  margin-top: 30px;
  width: 100%;
}

.skill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.progress {
  width: 60%;
  background: #2c2c3e;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 10px;
}

.languages-section, .code-management-section {
  margin-top: 30px;
  width: 100%;
}

.languages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.language {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #2c2c3e;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.stars {
  display: flex;
}
</style>
