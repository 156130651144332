<template>
  <div class="progress-bar">
    <div ref="progress" class="progress"></div>
  </div>
</template>

<script>
export default {
  name: 'AnimatedBar',
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.animateBar();
    });
  },
  methods: {
    animateBar() {
      this.$refs.progress.style.width = '0%';
      setTimeout(() => {
        this.$refs.progress.style.width = this.progress + '%';
      }, 1000);
    },
  },
};
</script>

<style scoped>
.progress-bar {
  width: 100%;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0;
}

.progress {
  height: 10px;
  background-color: #007bff;
  transition: width 2s;
}
</style>
