<template>
  <div v-if="visible" class="popup-overlay" @click.self="closePopup">
    <div class="popup-content">
      <button class="close-btn" @click="closePopup">&times;</button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupView',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closePopup() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s;
}

.popup-content {
  background: #1e1e2f;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(127, 0, 255, 0.5);
  max-width: 2000px; /* Adjusted width */
  height: auto;
  max-height: 90vh; /* Ensure it fits within the viewport */
  overflow-y: auto;
  position: relative;
  animation: popupSlideUp 0.5s;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  color: #fff;
  font-size: 2em;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popupSlideUp {
  from {
    transform: translateY(50px);
  }
  to {
    transform: translateY(0);
  }
}
</style>
